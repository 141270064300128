import React from 'react';
import './Card.css';

export const Card = ({children, innerClass, background, padding, margin, minHeight}) => {
  return (
    <div className='card-container' style={{padding: margin}}> {/* Mwahahahahaha*/}
      <div className={`card-inner-container ${innerClass}`} style={{background: background, padding: padding, minHeight: minHeight}}>
      {children}
      </div>
    </div>
  )
}

export default Card;
