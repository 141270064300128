import React from 'react';
import './Column.css';

export const Column = ({children, cx, mobile, px, styles}) => {
  return (
      <div style={{boxSizing: 'border-box', padding: px ? px : '0', styles}}
      className={`column
      ${mobile ? 'mobile-col' : ''}
      ${cx === 1 ? 'col-12' : ''}
      ${cx === 2 ? 'col-6' : ''}
      ${cx === 3 ? 'col-4' : ''}
      ${cx === 4 ? 'col-3' : ''}
      ${cx === 4.3 ? 'col-9' : ''}
      `}>
        {children}
      </div>
  )
}

export default Column;
