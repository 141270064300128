import React from 'react';
import './Page.css';

export const Page = ({children}) => {
  return(
    <>
    <div className='page-wrapper'>
      {children}
    </div>
    </>
  )
}

export default Page;
