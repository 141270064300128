import React from 'react';
import './Wrapper.css';

export const Wrapper = ({children, classes, sx}) => {
  return (
    <>
      <div className={`wrapper ${classes}`} style={sx}>
        {children}
      </div>
    </>
  )
}

export default Wrapper;
