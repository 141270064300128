import harry from './assets/harry.jpg';
import header from './assets/header.jpg';
import news1 from './assets/news-1.jpg';
import news2 from './assets/news-2.jpg';
import news3 from './assets/news-3.jpg';
import './App.css';
import {Page, Wrapper, Card, Column, Image} from './components/core'

function App() {
  return (
    <div className="App">
      <Page>
        <Wrapper>
          <Card padding={0} margin={2}>
            <h1 className="harry-header">Harry J.D. Miller</h1>
            <Image src={header} />
          </Card>
          <Column cx={4}>
            <Card padding={0} margin={2}>
              <Image src={harry} />
            </Card>
            <Card margin={2}>
              <div className={'contact-info-container'}>
              <p className="address-header"><b>Address</b></p>
              <p className="address-line">Department of Physics & Astronomy</p>
              <p className="address-line">The University of Manchester</p>
              <p className="address-line">Manchester</p>
              <p className="address-line">M13 9PL</p>
              <p className="address-header"><b>Email</b></p>
              <p className="address-line">dr.hjdmiller@gmail.com</p>
              <p className="address-header"><b>Profiles</b></p>
              <p className="address-line"><a href="https://scholar.google.co.uk/citations?view_op=list_works&hl=en&hl=en&user=LE4Qsw0AAAAJ&sortby=pubdate">Google Scholar</a></p>
              <p className="address-line"><a href="https://www.researchgate.net/profile/Harry-Miller-4">Researchgate</a></p>
              <p className="address-line"><a href="https://orcid.org/0000-0001-6275-0446">Orchid</a></p>
              </div>
            </Card>
          </Column>
          <Column  cx={4.3}>
            <Card margin={2} innerClass={'bio-container'}>
              <h2>Bio</h2>
              <p>I am a postdoctoral researcher working within the Manchester Noisy Quantum
              Systems Group (MaNQS) at the University of Manchester. I research primarily
              within the field of quantum thermodynamics, which is an emerging area of physics
              that aims to understand how the laws of thermodynamics behave at the smallest
              level. For example, results from this field have been used to study the performance
              of small thermal engines consisting of individual ions, as well as quantifying
              fundamental energetic costs underlying logical operations such as information
              erasure used in quantum computing.
              <br /><br />
              My current funding is from the Royal Commission for the Exhibition of 1851 to
              research and develop new theoretical techniques for determining optimal
              thermodynamic protocols in the quantum regime. The goal of this research is to
              uncover general design principles for optimising the thermodynamic performance
              of future microscopic devices such as quantum thermal machines, computers and
              sensors. Other avenues of my research concern topics in classical stochastic
              thermodynamics, quantum information geometry and quantum thermometry.</p>
            </Card>
          </Column>
          <Card margin={2}>
            <h2>Academic History</h2>
            <table>
              <tbody>
                <tr>
                  <td>2020-present</td>
                  <td><a href="https://royalcommission1851.org">Royal Commission Research Fellow</a>, MaNQs, University of Manchester</td>
                </tr>
                <tr>
                  <td>2019 - 2020</td>
                  <td>EPSRC Doctoral Prize Fellow, <a href="https://personalpages.manchester.ac.uk/staff/ahsan.nazir/">MaNQs</a>, University of Manchester </td>
                </tr>
                <tr>
                  <td>2019 - 2020</td>
                  <td>Postdoctoral Researcher, <a href="http://www.quantum-exeter.co.uk">Quantum Non-equilibrium group</a>, University of Exeter</td>
                </tr>
                <tr>
                  <td>2015 - 2019</td>
                  <td>PhD in Quantum Thermodynamics at University of Exeter, <b>Thesis</b>: <a href="https://ore.exeter.ac.uk/repository/bitstream/handle/10871/38832/THESIS_corrected_Harry%20Miller_no%20sign.pdf?sequence=1">Work, entropy
                      and uncertainties in thermodynamics beyond the classical and weak coupling
                      regime</a>
                  </td>
                </tr>
                <tr>
                  <td>2011 - 2015 </td>
                  <td>MSci Physics (1st Class Honours) at University of Bristol</td>
                </tr>
              </tbody>
            </table>
          </Card>
          <Card margin={2}>
            <h2>News</h2>
            <div className={'news-container'}>
              <div className={'news-item'}>
                <div className={'news-image'}>
                  <Image src={news1} />
                </div>
                <div className={'news-content'}>
                  <h3>Talk at the Fields Institute for Quantum Control Conference </h3>
                  <p>I spoke at the <a href="https://www.youtube.com/watch?v=YBVYSKtQn_c">Conference on Quantum Information and Quantum Control IX</a> about
my recent research detailing how to probabilistically violate the second law of
thermodynamics in microscopic systems.</p>
<iframe className={'youtube-iframe'} height="315" src="https://www.youtube.com/embed/YBVYSKtQn_c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
              <div className={'news-item'}>
                <div className={'news-image'}>
                  <Image src={news2} />
                </div>
                <div className={'news-content'}>
                  <h3>Physics World covers Quantum Fluctuations in Landauer Erasure</h3>
                  <p>Erasing information in the quantum regime is negatively impacted by the presence
of quantum coherence and uncertainty, as we have shown in our paper published
in Physical Review Letters. These findings were widely reported upon in popular
science press, including <a href=": https://physicsworld.com/a/quantum-coherence-turns-up-the-heat-onmaxwells-demon/">this excellent summary in Physics World.</a></p>
                </div>
              </div>
              <div className={'news-item'}>
                <div className={'news-image'}>
                  <Image src={news3} />
                </div>
                <div className={'news-content'}>
                  <h3>Entropy Best Paper 2022</h3>
                  <p>My co-authors and I have received the <a href="https://www.mdpi.com/journal/entropy/awards/1486">Entropy 2022 Best Paper Award</a> for our
published paper ‘Geometric Optimisation of Quantum Thermodynamic Processes’.
In it we give a pedagogical introduction to using methods from differential
geometry in quantum thermodynamics.</p>
                </div>
              </div>
            </div>
          </Card>
          <Card margin={2}>
            <h2>Publications</h2>
            <ol>
              <li><a href="https://arxiv.org/abs/2205.03065">Finite-time bounds on the probabilistic violation of the second law of
thermodynamics</a>, H. J. D. Miller, H. Mohammady, M. PerarnauLlobet, arXiv preprint arXiv:2205.03065 (2022)
</li>

<li><a href="https://journals.aps.org/pra/abstract/10.1103/PhysRevA.105.062434">Thermodynamic length and work optimisation for Gaussian
quantum states</a>, H. J. D. Miller & M. Mehboudi, Physical Review A
105 062434 (2022)
</li>

<li><a href="https://journals.aps.org/pre/abstract/10.1103/PhysRevE.103.052138">Joint statistics of work and entropy production along quantum
trajectories</a>, H. J. D. Miller, H. Mohammady, M. Perarnau-Llobet &
G. Guarnieri, Physical Review E 103 052138 (2021)
</li>

<li><a href="https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.126.210603">Thermodynamic uncertainty relation in slowly driven quantum heat
engines</a>, H. J. D. Miller, H. Mohammady, M. Perarnau-Llobet & G.
Guarnieri, Physical Review Letters 126 210603 (2021)
</li>

<li><a href="https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.125.260602">Geometry of work fluctuations versus efficiency in microscopic
thermal machines</a>, H. J. D. Miller & M. Mehboudi, Physical Review
Letters 125 260602 (2020)
</li>

<li><a href=" https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.125.160602">Quantum fluctuations hinder finite-time information erasure near the
Landauer limit</a>, H. J. D. Miller, G. Guarnieri, M. Mitchison & J.
Goold, Physical Review Letters 125 160602 (2020)
</li>

<li><a href="https://www.mdpi.com/1099-4300/22/10/1076">Geometric optimisation of quantum thermodynamic processes</a>, P.
Abiuso, H. J. D. Miller, M. Perarnau-Llobet & M. Scandi,
Entropy 22 1076 (2020)
</li>

<li><a href="https://journals.aps.org/prresearch/abstract/10.1103/PhysRevResearch.2.023377">Quantum work statistics close to equilibrium</a>, M. Scandi, H. J. D.
Miller, J. Anders & M. Perarnau-Llobet, Physical Review Research
2 023377 (2020)
</li>

<li><a href="https://journals.aps.org/prl/abstract/10.1103/PhysRevLett.123.230603">Work Fluctuations in Slow Processes: Quantum Signatures and
Optimal Control</a>, H. J. D. Miller, M. Scandi, J. Anders, M. PerarnauLlobet, Physical Review Letters 123 230603 (2019)
</li>

<li><a href=" https://link.springer.com/chapter/10.1007/978-3-319-99046-0_22">Hamiltonian of mean force for strongly-coupled systems, H. J. D.
Miller</a>, book chapter in 'Thermodynamics in the Quantum Regime’,
Springer pg. 531-549
</li>

<li><a href=" https://www.nature.com/articles/s41467-018-04536-7">Energy-temperature uncertainty relation in quantum
thermodynamics</a>, H. J. D. Miller & J. Anders, Nature
Communications 9 2203 (2018)
</li>

<li><a href="https://www.mdpi.com/1099-4300/20/3/200/htm">Leggett-Garg inequalities for quantum fluctuating work</a>, H. J. D.
Miller & J. Anders, Entropy 20 200 (2018)
</li>

<li><a href="https://journals.aps.org/pre/abstract/10.1103/PhysRevE.95.062123">Entropy production and time asymmetry in the presence of strong
interactions</a>, H. J. D. Miller & J. Anders, Physical Review E 95
062123 (2017)
</li>

<li><a href="https://journals.aps.org/pra/abstract/10.1103/PhysRevA.96.052115">Measurement-dependent corrections to work distributions arising
from quantum coherences</a>, P. Solinas, H. J. D. Miller & J. Anders,
Physical Review A 96 052115 (2017)
</li>

<li><a href="https://iopscience.iop.org/article/10.1088/1367-2630/aa703f/pdf">Time-reversal symmetric work distributions for closed quantum
dynamics in the histories framework</a>, H. J. D. Miller & J. Anders,
New Journal of Physics 19, 062001 (2017)
</li>


            </ol>
          </Card>
        </Wrapper>
      </Page>
    </div>
  );
}

export default App;
